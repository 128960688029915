import React from 'react'
import styled from 'styled-components'
import ArrowPattern from './images/arrow-pattern'
import Alex from './images/alex'
import Cata from './images/cata'
import { SecondaryOutLink } from '../components/button'
import { H3 } from './headings'

const MemberCard = ({ name, headline, description, linkedin, github}) => {
  return (
    <Container>
      <Card>
        {
          name.includes('Alex')
          ? <Alex />
          : <Cata />
        }
        <ArrowPattern style={{ width: "100%" }}/>
      </Card>
      <H3>{name}</H3>
      <SectionTitle style={{ paddingTop: 5 }}>
        <span>{headline}</span>
      </SectionTitle>
      <p>{description}</p>
      <LinksContainer>
        <SecondaryOutLink to={linkedin} label="LinkedIn ↗︎"/>
        <SecondaryOutLink to={github} label="Github ↗︎"/>
      </LinksContainer>

    </Container>
  )
}

const Container = styled.div`
  width: 320px;
  display: flex;
  flex-direction: column;
  margin: 0 20px;

  @media (max-width: 767px) {
    width: 47%;
    margin: 0 5px;
  }

  h3 {
    text-align: left;
    font: Bold 37px/43px Vremena Grotesk;
    letter-spacing: -0.93px;
    color: #040505;
    opacity: 1;

    margin: 30px 0 5px 0;
  }

  p {
    text-align: left;
    font: Regular 22px/26px Noah;
    letter-spacing: -0.55px;
    color: #000000;
    opacity: 1;
  }
`

const LinksContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  width: 200px;

  a {
    text-align: left;
    text-decoration: underline;
    font: Bold 16px/19px Vremena Grotesk;
    letter-spacing: -0.4px;
    color: #000000;
  }

  @media (max-width: 767px) {
    flex-direction: column;
    width: 100%;
  }
`

const Card = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  border: 1px solid #E23C30;
  justify-content: flex-start;
`

const SectionTitle = styled.div`
  border-bottom: 1px solid black;
  padding: 30px 0 20px 0;
  margin: 0 0 50px 0;
  width: 100%;
  
  span {
    text-align: left;
    font: 20px/24px Vremena Grotesk;
    letter-spacing: -0.5px;
    color: #000000;
    opacity: 1;
  }

  @media (max-width: 767px) {
    span {
      font: 15px/16px Vremena Grotesk !important;
    }
  }
`

export default MemberCard
