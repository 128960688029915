import React from 'react'
import styled from 'styled-components'

const Question = ({ question, answers }) => {
  return (
    <Container>
      <h4>{question}</h4>
      <hr />
        {answers.map(answer => <p>{answer}</p>)}
    </Container>
  )
}

const Container = styled.div`
  width: 80%;
  display: flex;
  flex-direction: column;

  hr {
    height: 1px;
    background-color: #000000
  }

  h4 {
    text-align: left;
    font: Bold 21px/24px Vremena Grotesk;
    letter-spacing: -0.52px;
    color: #000000;
    opacity: 1;
  }

  p {
    text-align: left;
    font: Regular 22px/27px Noah;
    letter-spacing: -0.55px;
    color: #000000;
    opacity: 1;
  }

  @media (max-width: 767px) {
    width: 100% !important;
    padding: 0 30px;
  }
`

export default Question
