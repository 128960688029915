import React from "react"
import styled from 'styled-components'

import Layout from "../components/layout"
import SEO from "../components/seo"
import Footer from '../components/footer'
import Header from '../components/header'
import Section from '../components/sectionContainer'
import AboutCover from "../components/images/about-cover"
import Question from '../components/question'
import ManifestoLogo from '../assets/icons/manifesto-logo.svg'
import MemberCard from '../components/member-card'
import SectionTitle from '../components/section-title'
import { H2 } from '../components/headings'

const IndexPage = () => (
  <Layout>
    <SEO title="Appes - About and Manifesto" />
    <Header />
    <CoverSection bgColor="#FFFFFF">
      <CoverContainer>
        <AboutCover />
      </CoverContainer>
    </CoverSection>
    <Section style={{ paddingBottom: 60 }}>
      <ManifestoContainer>
        <div style={{ maxWidth: 970 }}>
          <H2>Our promise is to bring digital clarity to the world, one app at a time.</H2>
        </div>
        <Manifesto>
          <QuestionsContainer>
            <Question
              question="Who?"
              answers={["We’re a curious bunch of techies, designers, and thinkers who excel at solving problems."]}
            />
            <Question
              question="What?"
              answers={["We create web and mobile solutions to achieve specific goals. Custom apps, essentially."]}
            />
            <Question
              question="How?"
              answers={["We listen to your story, we ask the right questions, and we find new ways to apply technology to achieve your aims, now and in the future."]}
            />
            <Question
              question="Why?"
              answers={["Because we love doing it. We’re excited by pushing the limits of tech, and by growing and evolving solutions to meet new and emerging needs.","Inventiveness, creativity, adaptation - these qualities are at the heart of who we are as humans. We learn from the past to create a better tomorrow."]}
            />
          </QuestionsContainer>
          <ManifestoCard>
            <H2>Our <Underline>manifesto</Underline></H2>
            <div>
              <ol>
                <li><Underline>Honesty</Underline> is vital for success.</li>
                <li>Match each solution <Underline>to the client</Underline>.</li>
                <li><Underline>Follow through</Underline> on every promise.</li>
                <li>Care and <Underline>you'll be cared for</Underline>.</li>
                <li><Underline>Know</Underline> what you do, <Underline>show</Underline> what you do.</li>
                <li>Never, ever, stop <Underline>learning</Underline>.</li>
                <li><Underline>Remember</Underline> we’re all on the same team.</li>
                <li>Tech must be accessible for <Underline>all people</Underline>.</li>
                <li>Build solutions capable of <Underline>evolution</Underline>.</li>
              </ol>
            </div>
            <WatermarkLogo />
          </ManifestoCard>
        </Manifesto>
      </ManifestoContainer>
    </Section>
    <Section style={{ paddingBottom: 100 }}>
      <TeamContainer>
        <TeamTitle>
          <span>Our founders</span>
        </TeamTitle>
        <MembersContainer>
          <MemberCard
            name="Cătălin Tudorache"
            headline="Co-founder & Tech Consultant"
            description="Stubborn, superficial and selfish, founder wanna be, driven by greed and passionate about power. Only joking, I’m the complete opposite!"
            linkedin="https://www.linkedin.com/in/catalintudorache/"
            github="https://github.com/charlietango/"
          />
          <MemberCard
            name="Alex Muscă"
            headline="Co-founder & Tech Consultant"
            description="If you’re going to do something, do it right or don’t do it at all. I appreciate beautifully written code and I strive to do the same."
            linkedin="https://www.linkedin.com/in/alexandru-musc%C4%83-50b5a491/"
            github="https://github.com/alexmcode/"
          />
        </MembersContainer>
      </TeamContainer>
    </Section>
    <Footer />
  </Layout>
)

const Underline = styled.span`
  text-decoration: underline;
`

const CoverSection = styled(Section)`
  margin-top: 50px;

  @media (max-width: 1336px) {
    margin-top: 0;
  }
`

const CoverContainer = styled.div`
  min-height: inherit;
  min-width: inherit;
`

const ManifestoContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  position: relative;
  padding: 100px 0px 0px 0px;

  h2 {
    text-align: center;
  }

  @media (max-width: 1336px) {
    padding: 60px 0px 0px 0px;
    h2 {
      text-align: center;
      margin: 0 100px;
    }
  }

  @media (max-width: 767px) {
    padding: 40px 0px 0px 0px;

    h2 {
      font: 25px/29px Vremena Grotesk !important;
      text-align: center;
      margin: 0 30px;
    }
  }
`

const WatermarkLogo = styled(ManifestoLogo)`
  width: 370px;
  height: 300px; 
  position: absolute;
  bottom: 0;
  left: 0;

  @media (max-width: 767px) {
    width: 200px;
    height: 160px;
  }
`

const MembersContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
`

const Manifesto = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: center;
  width: 100%;
  height: 100%;
  margin: 40px 0px 0px 0px;

  @media (max-width: 1336px) {
  }
  
  @media (max-width: 767px) {
    flex-direction:column;
  }
`

const QuestionsContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 300px;

  @media (max-width: 767px) {
    width: 100% !important;
    max-width: 100%;
    align-items: center;
  }
`

const ManifestoCard = styled.div`
  display: flex;
  flex-direction: column;
  background-color: #E23C30;
  margin: 50px 0 35px 0;
  padding: 40px;
  max-height: 700px;
  max-width: 700px;
  position: relative;

  @media (max-width: 1336px) {
    ol {
      margin-left: 25px !important;
    }

    h2 {
      margin: 0;
    }
  }

  @media (max-width: 767px) {
    ol {
      margin-bottom: 150px;
    }
  }

  h2 {
    text-align: left;
    font: Bold 80px/81px Vremena Grotesk;
    letter-spacing: -2px;
    color: #FFFFFF;
  }

  ol {
    margin-left: 220px;
    margin-top: 10px;
  }

  li {
    text-align: left;
    font: Bold 19px/28px Vremena Grotesk;
    letter-spacing: -0.47px;
    color: #FFFFFF;
    margin-bottom: 3px;
  }
`

const TeamContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  height: 100%;
  padding: 0 60px;


  @media (max-width: 767px) {
    padding: 0 20px;
  }
`

const TeamTitle = styled(SectionTitle)`
  padding-left: 95px;

  @media (max-width: 1336px) {
    padding: 0;
  }
`

export default IndexPage
